<template>
  <q-card class="my-card text-left q-ma-md q-pb-sm">
    <q-list>
      <q-item>
        <q-item-section>
          <q-item-label class="text-weight-bold">{{
            $t("Profile.privacy")
          }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item class="q-ml-md">
        <q-item-section>
          <!-- <q-item-label
            @click="dialog = true"
            class="text-weight-bold"
            style="text-decoration: underline"
            >Cambiar contraseña
          </q-item-label>
          <q-item-label
            @click="dialog = true"
            class="text-weight-bold"
            style="text-decoration: underline"
            >Descargar mis datos
          </q-item-label>
          <q-item-label
            @click="dialog = true"
            class="text-weight-bold"
            style="text-decoration: underline"
            >Eliminar cuenta
          </q-item-label> -->

          <q-item class="q-ma-none q-pa-none">
            <q-item-section class="q-ma-none q-pa-none">
              <q-item-label
                @click="changePassword = true"
                class="cursor-pointer q-mb-sm underline-text"
                >{{ $t("Login.change-password") }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon class="icon-white" color="primary" name="vpn_key" />
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none">
            <q-item-section class="q-ma-none q-pa-none">
              <q-item-label
                style="text-decoration: underline"
                class="cursor-pointer"
                @click="confirmPageOmni = true"
              >
                {{ $t("Profile.contact") }}
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                class="icon-white"
                color="primary"
                name="email"
                @click="confirmPageOmni = true"
              />
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none">
            <q-item-section class="q-ma-none q-pa-none">
              <q-item-label
                style="text-decoration: underline"
                class="cursor-pointer"
                @click="dialogPrivacyPolicy = true"
                >{{ $t("Login.privacy-politics") }}</q-item-label
              >
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                color="primary"
                class="cursor-pointer icon-white"
                name="policy"
                @click="dialogPrivacyPolicy = true"
              />
            </q-item-section>
          </q-item>

          <q-item class="q-ma-none q-pa-none">
            <q-item-section class="q-ma-none q-pa-none">
              <q-item-label
                style="text-decoration: underline"
                class="cursor-pointer"
                @click="dialogQA = true"
                >{{ $t("FAQ.title") }}</q-item-label
              >
            </q-item-section>
            <q-item-section avatar>
              <q-icon
                color="primary icon-white"
                class="cursor-pointer"
                name="help_outline"
                @click="dialogQA = true"
              />
            </q-item-section>
          </q-item>
        </q-item-section>
      </q-item>
    </q-list>
  </q-card>

  <q-dialog persistent v-model="confirmPageOmni">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section class="text-center">
        <div class="text-body1 q-pb-md">
          {{ $t("Login.web-omniwallet") }}
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <a
          class="btn-home"
          href="https://www.omniwallet.net/#contacta"
          target="_blank"
        >
          <q-btn
            target="_blank"
            class="btn-home"
            :label="$t('Login.web-access')"
            :style="{ backgroundColor: settings.color_primary }"
          />
        </a>
      </q-card-actions>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('Login.go-back-app')"
          :style="{ backgroundColor: settings.color_accent }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <PrivacyPoliticsDialog v-model="dialogPrivacyPolicy" />

  <ChangePassword v-model="changePassword" />
  <QAquestions v-model="dialogQA" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

const PrivacyPoliticsDialog = defineAsyncComponent(() =>
  import("../../../shared/components/PrivacyPoliticsDialog")
);

const ChangePassword = defineAsyncComponent(() =>
  import("../profile/privacy/ChangePassword.vue")
);

const QAquestions = defineAsyncComponent(() =>
  import("../../../shared/components/QAquestions.vue")
);

export default {
  components: {
    PrivacyPoliticsDialog,
    ChangePassword,
    QAquestions,
  },
  data() {
    return {
      dialogQA: false,
      dialogPrivacyPolicy: false,
      changePassword: false,
      logOutDialog: false,
      confirmPageOmni: false,
    };
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },

    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
  },
};
</script>

<style lang="scss" scoped>
.q-item-label {
  cursor: pointer;
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
